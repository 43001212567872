import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d87567c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "permission-card q-mt-md min-w-1000px",
  flat: "flat"
}
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = { class: "permission-name-section items-center justify-center" }
const _hoisted_5 = { class: "fw-bolder fs-5 p-10" }
const _hoisted_6 = { class: "permission-key-section ms-10 p-5 d-flex flex-column justify-content-center" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "svg-icon svg-icon-3" }
const _hoisted_9 = { class: "my-2" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 0,
  class: "permission-action-section d-flex align-items-center justify-content-center"
}
const _hoisted_12 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_PermissionCard = _resolveComponent("PermissionCard", true)!

  return (_ctx.permission)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["children", _ctx.permissionCardClass])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(`${_ctx.permission.permName} (${_ctx.permission.requiredAuth.threshold})`), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.permission.requiredAuth.keys.keys, (k) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: k.key,
                    class: "my-2 fs-5"
                  }, [
                    _createTextVNode(_toDisplayString(`+${k.weight}     ${k.key}`) + " ", 1),
                    _createElementVNode("button", {
                      class: "btn btn-icon btn-active-color-primary btn-sm me-1 ms-5",
                      onClick: ($event: any) => (_ctx.copyKey(k.key))
                    }, [
                      _createElementVNode("span", _hoisted_8, [
                        _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen054.svg" })
                      ])
                    ], 8, _hoisted_7)
                  ]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.permission.requiredAuth.accounts.accounts, (a) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `${a.permission.actor}-${a.permission.permission}`
                  }, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("span", null, _toDisplayString(`+${a.weight}     `), 1),
                      _createElementVNode("span", {
                        class: "fw-bolder fs-5 m-10",
                        innerHTML: _ctx.formatAccount(a.permission.actor, 'account')
                      }, null, 8, _hoisted_10),
                      _createElementVNode("span", null, " @" + _toDisplayString(a.permission.permission), 1)
                    ])
                  ]))
                }), 128))
              ]),
              (_ctx.permission.permissionLinks.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.permission.permissionLinks, (link) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: link.action
                      }, _toDisplayString(link.code) + "::" + _toDisplayString(link.action), 1))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ], 2),
        (_ctx.permission.children && _ctx.permission.children.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.permission.children, (p, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: p.perm_name
                }, [
                  _createVNode(_component_PermissionCard, {
                    class: "permission-card-wrapper my-4",
                    permission: p,
                    depth: _ctx.depth + 1,
                    isLast: index == _ctx.permission.children.length - 1
                  }, null, 8, ["permission", "depth", "isLast"])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}