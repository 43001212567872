import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "alert alert-dismissible bg-light-info border-dashed border-info d-flex flex-column flex-sm-row p-5 mb-5" }
const _hoisted_2 = { class: "svg-icon svg-icon-1" }
const _hoisted_3 = { class: "d-flex flex-column pe-0 pe-sm-10 ms-5 w-100" }
const _hoisted_4 = { class: "d-flex justify-content-between" }
const _hoisted_5 = { class: "mb-1" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "fs-5 text-primary text-wrap" }
const _hoisted_8 = { class: "ms-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KeyChildItem = _resolveComponent("KeyChildItem", true)!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.children, (child, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen051.svg" })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h5", _hoisted_5, _toDisplayString(child.authority), 1),
              _createElementVNode("div", null, [
                _createElementVNode("span", {
                  style: {"float":"right"},
                  onClick: ($event: any) => (_ctx.copyPVKey(child.key)),
                  class: "p-0 m-0 svg-icon badge cursor-pointer svg-icon-1 badge badge-light-info border-info"
                }, [
                  _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen054.svg" })
                ], 8, _hoisted_6)
              ])
            ]),
            _createElementVNode("span", _hoisted_7, _toDisplayString(child.key), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          (child.children.length > 0)
            ? (_openBlock(), _createBlock(_component_KeyChildItem, {
                key: 0,
                children: child.children
              }, null, 8, ["children"]))
            : _createCommentVNode("", true)
        ])
      ]))
    }), 128))
  ]))
}