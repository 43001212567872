export default class MatomoService{
  static trackPageView(title){
    var accountName = window.localStorage.getItem("profile")
    if(accountName){
      var t = JSON.parse(accountName)
      accountName = t.wallet[0].value
    }else{
      accountName = window.localStorage.getItem("fakeToken")
    }

    try {
      if(window._paq == undefined)
        return;
      window._paq.push(['setCustomUrl', window.location.href]);    
      window._paq.push(['setDocumentTitle', title? title:window.document.title]);  
      if(accountName)      
        window._paq.push(['setUserId', accountName]);
      window._paq.push(['trackPageView']);     
    } catch (error) {
      
    }
  }

  static trackEvent(category,action){
    window._paq.push(['trackEvent',category,action]);
  }

  static trackEvent(category,action,name){
    window._paq.push(['trackEvent',category,action,name]);
  }

  static trackEvent(category,action,name,value){
    window._paq.push(['trackEvent',category,action,name,value]);
  }
}