
import { defineComponent, ref } from "vue";
import { useToast } from "vue-toastification";
//component
import KeyChildItem from '@/pages/account/accountTab/overview/components/overviewTabs/tabs/keys/KeyChildItem.vue'

export default defineComponent({
  name: "key-item",

  props:['keys'],

  components:{ 
    KeyChildItem
  },
  data(){
    const toast = useToast();
    return{
      toast,
    }
  },
  methods:{
    copyPVKey(key:string){
      console.log("key ===> " , key);
      
      navigator.clipboard.writeText(key); 
      this.toast.success(`Copied`) ;

    }
  }
});
