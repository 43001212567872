
import { defineComponent } from "vue";

export default defineComponent({
  name: "loading",

  props:['height' , 'title' , 'background'],

  components: {
  },

});
