import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "alert alert-dismissible bg-light-info border-dashed border-info d-flex flex-column flex-sm-row p-5 mb-5" }
const _hoisted_2 = { class: "svg-icon svg-icon-1" }
const _hoisted_3 = { class: "d-flex flex-column pe-0 pe-sm-10 ms-5 w-100" }
const _hoisted_4 = { class: "d-flex justify-content-between" }
const _hoisted_5 = { class: "mb-1" }
const _hoisted_6 = { class: "fs-5 text-primary text-wrap" }
const _hoisted_7 = { class: "ms-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KeyChildItem = _resolveComponent("KeyChildItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, [
        _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen051.svg" })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.keys.root.authority), 1),
          _createElementVNode("div", null, [
            _createElementVNode("span", {
              style: {"float":"right"},
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyPVKey(_ctx.keys.root.key))),
              class: "p-0 m-0 svg-icon badge cursor-pointer svg-icon-1 badge badge-light-info border-info"
            }, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen054.svg" })
            ])
          ])
        ]),
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.keys.root.key), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.keys.children.length > 0)
        ? (_openBlock(), _createBlock(_component_KeyChildItem, {
            key: 0,
            children: _ctx.keys.children
          }, null, 8, ["children"]))
        : _createCommentVNode("", true)
    ])
  ]))
}