
import { defineComponent, PropType } from 'vue';
import { useToast } from "vue-toastification";

import { Permission } from '@/models/AccountModel'
import PermissionCard from '@/pages/account/accountTab/overview/components/overviewTabs/tabs/keys/PermissionCard.vue'

export default defineComponent({
  name: 'key-item',

  props: {
    permission: {
      type:Permission,
      required: false,
      default: null
    },
    depth: {
      type: Number,
      default: 0
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },

  components:{
    PermissionCard
  },

  data(){
    const toast = useToast();
    return{
      toast
    }
  },

  computed: {
    branchTopClass() {
      return this.isLast ? 'branch-corner' : 'branch-side-top';
    },
    branchBottomClass() {
      return this.isLast ? '' : 'branch-side';
    },
    permissionCardClass() {
      return this.depth == 0 ? 'owner-permission' : '';
    }
  },

  methods: {
    formatAccount(
      name: string,
      type: 'account' | 'transaction' | 'block'
    ): string {
      return `<a href="/${type}/${name}" class="hover-dec">${name}</a>`;
    },

    copyKey(key:string){
      navigator.clipboard.writeText(key); 
      this.toast.success(`Copied`) ;
    }
  }
});
