
import { defineComponent, ref } from "vue";
//component
import KeyItem from '@/pages/account/accountTab/overview/components/overviewTabs/tabs/keys/KeyItem.vue'
import PermissionCard from '@/pages/account/accountTab/overview/components/overviewTabs/tabs/keys/PermissionCard.vue'
import Loading from '@/components/loading/Loading.vue'
//services
import AccountService from "@/services/AccountServices";
//model
import AccountModel, { Permission , PermissionLinks , PermissionLink } from '@/models/AccountModel'
export default defineComponent({
  name: "keys-tab",

  props:{
    account:AccountModel,
  },

  components:{ 
    KeyItem,
    Loading,
    PermissionCard
  },

  data(){
    let keys:Permission = new Permission()
    return{
      keys,
      loading:true
    }
  },

  methods:{
    async init(){
      this.loading = true
      let stringPermissions:string = JSON.stringify(this.account?.permissions as Permission[])
      let permissions:Permission[] = JSON.parse(stringPermissions) as Permission[]  

      let links:PermissionLinks = new PermissionLinks()
      try {
        links = await AccountService.getPermissionsLink(this.account?.account_name as string) 
      } catch(e){}
      
      for (let p of permissions) {
        p.permissionLinks = links.links.filter((l) => l.permission == p.permName) as any        
      }
          
      this.keys = this.sortPermissions(permissions);
      
      this.loading = false
    },

    sortPermissions(permissions: Permission[]) {      
      let result:Permission = permissions.find((p) => p.permName === 'owner') as Permission;      
      permissions = permissions.filter((p) => p.permName !== 'owner');
      
      const getChildren = (parent: Permission, perms: Permission[]) => {
        let children = perms.filter((p) => p.parent === parent.permName);
        for (let child of children) {
          child.children = getChildren(child, perms);
        }
        return children;
      };

      result.children = getChildren(result, permissions);
      return result;
    }
  },

  mounted(){
    this.init()
  },

});
